import { AnalyticsClient } from './types';

// We we'll not be adding logic in the manager
// so it's ok to just expose the client
export class AnalyticsManager {
  public client: AnalyticsClient;

  constructor(client: AnalyticsClient) {
    this.client = client;
  }
}
