import { createSharedPathnamesNavigation } from 'next-intl/navigation';

import { PaymentMethodType } from '@/domain/project/services/invest/type';

export type LinkProps = Parameters<
  ReturnType<typeof createSharedPathnamesNavigation>['Link']
>[number];

export type UserInfoPayload = {
  user_status?:
    | 'inscrit'
    | 'dossier complété'
    | 'dossier validé'
    | 'investisseur';
  user_info?: 'particulier' | 'entreprise';
  user_isConnected: boolean;
};

export type ECommerceItem = {
  item_id: string;
  item_name: string;
  item_price: number;
  item_brand: string;
};

type PageViewEventPayload = {
  event: 'page_view';
  page_view: string; // Means to be the title of the page
  page_location: string; // Means to be the url of the page
} & UserInfoPayload;

type ClickCtaEventPayload = {
  event: 'click_cta';
  link_text: string;
  link_url: string;
  link_category: 'button' | 'menu';
};

type SignUpEventPayload = {
  event: 'sign_up';
  method: 'Google' | 'Facebook' | 'LinkedIn' | 'Email';
};

type EmailCollectPayload = {
  event: 'email_collect';
};

type SignUpCompletedPayload = {
  event: 'sign_up_complete';
};

type ViewItemPayload = {
  event: 'view_item';
  ecommerce: {
    items: Array<ECommerceItem>;
  };
};

type AddToCartPayload = {
  event: 'add_to_cart';
  ecommerce: {
    items: Array<ECommerceItem>;
  };
};

type FormSubmitPayload = {
  event: 'form_submit';
  form_id: string;
};

type BeginCheckoutPayload = {
  event: 'begin_checkout';
  ecommerce: {
    value: number;
    currency: 'EUR';
    items: Array<ECommerceItem>;
  };
};

type PurchasePayload = {
  event: 'purchase';
  ecommerce: {
    value: number;
    currency: 'EUR';
    items: Array<ECommerceItem>;
    coupon: null;
    mode_invest: PaymentMethodType;
  };
};

export type EventPayload =
  | ClickCtaEventPayload
  | SignUpEventPayload
  | EmailCollectPayload
  | SignUpCompletedPayload
  | ViewItemPayload
  | AddToCartPayload
  | BeginCheckoutPayload
  | PurchasePayload
  | PageViewEventPayload
  | FormSubmitPayload;

type KeysOfUnion<T> = T extends T ? keyof T : never;

export const propertyToClear: Array<KeysOfUnion<EventPayload>> = [
  'ecommerce',
  // 'event', we specifically don't want to erase event
  'link_category',
  'link_text',
  'link_url',
  'method',
  'page_location',
  'page_view',
];

export interface AnalyticsClient {
  sendInitialDataLayerPush: (payload: UserInfoPayload) => void;
  sendEvent: (payload: EventPayload) => void;
}
