'use client';

import Image from 'next/image';

import { useTranslations } from 'next-intl';

import Routes from '@/lib/router/routes';
import Link from '@/lib/translation/components/link';

interface LogoProps {
  onClick?: () => void;
  size?: 'normal' | 'small';
  theme?: 'light' | 'dark';
  className?: string;
}

const Logo = ({
  onClick,
  size = 'normal',
  theme = 'dark',
  className,
}: LogoProps) => {
  const t = useTranslations();

  const sizes: Record<typeof size, { width: number; height: number }> = {
    normal: { width: 192, height: 32 },
    small: { width: 144, height: 24 },
  };

  return (
    <Link onClick={onClick} href={Routes.Home} className={className}>
      <Image
        {...sizes[size]}
        src={
          theme === 'dark' ? '/logo-sowefund.svg' : '/logo-sowefund-light.svg'
        }
        alt={t('header.logo')}
      />
    </Link>
  );
};

export default Logo;
