import * as Sentry from '@sentry/nextjs';

import clientConfig from '@/lib/config/client-config';

/**
 * Enum based on Sentry.SeverityLevel string literal type
 */
enum SeverityLevel {
  Fatal = 'fatal',
  Error = 'error',
  Warning = 'warning',
  Log = 'log',
  Info = 'info',
  Debug = 'debug',
}

interface IErrorReporting {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error(error: any, data?: any): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  info(error: any, data?: any): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  warn(error: any, data?: any): void;
  setUser(user: { id: string }): void;
  removeUser(): void;
}

function replaceError(_key: string, value: unknown) {
  if (value instanceof Error) {
    return { name: value.name, message: value.message, stack: value.stack };
  }
  return value;
}

/**
 * Main class
 */
class ErrorReporting implements IErrorReporting {
  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-explicit-any
  private log(level: Sentry.SeverityLevel, error: any, data: any) {
    return Sentry.withScope((scope) => {
      scope.setLevel(level);
      if (data) {
        scope.setExtra('data', JSON.stringify(data, replaceError));
      }
      if (error instanceof Error) {
        Sentry.captureException(error);
      } else {
        Sentry.captureMessage(JSON.stringify(error, replaceError));
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public setUser(user: Sentry.User) {
    Sentry.getCurrentScope().setUser(user);
  }

  // eslint-disable-next-line class-methods-use-this
  public removeUser() {
    Sentry.getCurrentScope().setUser(null);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public info(error: any, data?: any) {
    this.log(SeverityLevel.Info, error, data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public warn(error: any, data?: any) {
    this.log(SeverityLevel.Warning, error, data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public error(error: any, data?: any) {
    this.log(SeverityLevel.Error, error, data);
  }
}

/**
 * Mocks class
 */
class ErrorReportingMocks implements IErrorReporting {
  // eslint-disable-next-line class-methods-use-this, no-empty-function
  setUser() {}

  // eslint-disable-next-line class-methods-use-this, no-empty-function
  removeUser() {}

  // eslint-disable-next-line class-methods-use-this, no-empty-function
  info() {}

  // eslint-disable-next-line class-methods-use-this, no-empty-function
  warn() {}

  // eslint-disable-next-line class-methods-use-this, no-empty-function
  error() {}
}

const errorReporting: IErrorReporting = clientConfig.sentry.isEnabled
  ? new ErrorReporting()
  : new ErrorReportingMocks();

export default errorReporting;
