enum Routes {
  Home = '/',
  RootPrismicPages = '/:uid',

  SignUpRegister = '/sign-up/register',
  SignUpIdentity = '/sign-up/identity',
  SignUpPersonalInformation = '/sign-up/identity/personal-information',
  SignUpAreYouACompany = '/sign-up/identity/are-you-a-company',
  SignUpCompanyInformation = '/sign-up/identity/company-information',
  SignUpFavoriteIndustries = '/sign-up/favorite-industries',
  SignUpFinancialSituation = '/sign-up/financial-situation',
  SignUpInvestmentKnowledgeQuestionnaire = '/sign-up/investment-knowledge-questionnaire',
  SignUpInvestmentGoals = '/sign-up/investment-knowledge-questionnaire/investment-goals',
  SignUpStartupInvestmentExperience = '/sign-up/investment-knowledge-questionnaire/startup-investment-experience',
  SignUpInvestmentExperience = '/sign-up/investment-knowledge-questionnaire/investment-experience',
  SignUpRiskAwareness = '/sign-up/investment-knowledge-questionnaire/risk-awareness',
  SignUpQuestionnaireResults = '/sign-up/investment-knowledge-questionnaire/results',
  SignUpIdentityValidation = '/sign-up/identity-validation',
  SignUpThankYou = '/sign-up/thank-you',

  SignIn = '/sign-in',
  Projects = '/projects',
  HowProjectWorks = '/how-it-works',
  Project = '/projects/:slug',
  GetFunded = '/get-funded',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  ProjectInvestAmount = '/investments/:id/invest/amount',
  ProjectInvestInvestMode = '/investments/:id/invest/mode',
  ProjectInvestPaymentMethod = '/investments/:id/invest/payment-method',
  ProjectInvestSummary = '/investments/:id/invest/summary',
  ProjectInvestPayment = '/investments/:id/invest/payment',
  ProjectInvestSignSubscriptionWarrant = '/investments/:id/invest/sign-subscription-warrant',
  ProjectInvestValidation = '/investments/:id/invest/validation',
  Portfolio = '/account/portfolio',
  LegalEntityDetail = '/account/portfolio/legal-entity/:id',
  LegalEntityDocument = '/account/portfolio/document/:id',
  TransactionDetail = '/account/transactions/:transactionId',
  InvestmentVehicleExemptTaxCertificate = '/account/portfolio/legal-entity/:legalEntityId/investment/:vehicleId/exempt-tax-certificate',
  InvestmentVehicleInvestmentBill = '/account/portfolio/legal-entity/:legalEntityId/investment/:vehicleId/investment/:investmentId/bill',
  InvestmentVehicleInvestmentSubscriptionReport = '/account/portfolio/legal-entity/:legalEntityId/investment/:vehicleId/investment/:investmentId/subscription-report',
  GeneralMeetingAnnualAccounts = '/account/portfolio/general-meeting/:generalMeetingId/annual-accounts',
  GeneralMeetingChairmanReportAndResolutions = '/account/portfolio/general-meeting/:generalMeetingId/chairman-report-and-resolutions',
  GeneralMeetingMinutes = '/account/portfolio/general-meeting/:generalMeetingId/minutes',
  GeneralMeetingSignProxy = '/account/portfolio/general-meeting/:generalMeetingId/proxy-signature',
  GeneralMeetingProxyToSign = '/account/portfolio/general-meeting/:generalMeetingId/proxy-to-sign',
  LegalEntityShareOwnershipCertificate = '/account/portfolio/legal-entity/:legalEntityId/shareholding-proof',
  Withdraw = '/account/wallet/withdraw',
  Supply = '/account/wallet/supply',
  SupplyTransaction = '/account/wallet/supply/card-transaction/:supplyTransactionId',
  SupplyTransactionSuccess = '/account/wallet/supply/card-transaction/:supplyTransactionId/success',
  Rib = '/account/rib',
  Company = '/account/company',
  FavoriteProjects = '/account/favorite',
  Profile = '/account/details',
  Transactions = '/account/transactions',
  Wallet = '/account/wallet',
  Guides = '/guides',
}

export default Routes;
