import { Formats, TranslationValues } from 'next-intl';
import { Pathnames } from 'next-intl/routing';

export const i18n = {
  defaultLocale: 'fr-FR' as const,
  locales: ['en-US', 'fr-FR'] as const,
  localePrefix: 'as-needed' as const,
};

export type Locale = (typeof i18n)['locales'][number];

export type TFunction = (
  key: string,
  values?: TranslationValues,
  formats?: Partial<Formats>,
) => string;

export const getTranslationList = (
  t: TFunction,
  listKey: string,
  listLength: number,
): string[] => {
  return Array.from(Array(listLength)).map((_, i) => t(`${listKey}.${i}`));
};

export const pathnames = {
  '/projects': {
    'fr-FR': '/investir',
    'en-US': '/projects',
  },
  '/projects/[slug]': {
    'fr-FR': '/investir/[slug]',
    'en-US': '/projects/[slug]',
  },
  '/get-funded': {
    'fr-FR': '/lever-des-fonds',
    'en-US': '/get-funded',
  },
  '/how-it-works': {
    'fr-FR': '/comment-ca-marche',
    'en-US': '/how-it-works',
  },
  '/sign-in': {
    'fr-FR': '/connexion',
    'en-US': '/sign-in',
  },
  '/about-us/who-we-are': {
    'fr-FR': '/a-propos/qui-sommes-nous',
    'en-US': '/about-us/who-we-are',
  },
  '/about-us/press': {
    'fr-FR': '/a-propos/presse',
    'en-US': '/about-us/press',
  },
  '/partners/cgp': {
    'fr-FR': '/partenaires/conseillers-gestion-patrimoine',
    'en-US': '/partners/cgp',
  },
} satisfies Pathnames<(typeof i18n)['locales']>;
