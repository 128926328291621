/* eslint-disable import/prefer-default-export */
import { ExitIcon } from '@radix-ui/react-icons';
import { getTranslations } from 'next-intl/server';

import { cachedGetUserInfo } from '@/app/[locale]/_providers/auth/action';
import { Icons } from '@/app/_components/ui/icons';
import client from '@/lib/prismic/client';
import generatePath from '@/lib/router/generate-path';
import Routes from '@/lib/router/routes';

import { MenuItemDocument } from '../../../../prismicio-types';

import { ChildrenMenuItem, MenuItem } from './type';

export function isChildrenMenuItem(item: MenuItem): item is ChildrenMenuItem {
  return !Object.prototype.hasOwnProperty.call(item, 'items');
}

export const getMenuItems = async (): Promise<
  Array<MenuItemDocument<string>>
> => {
  return client.getAllByType('menu_item');
};

export const getConnectedMenu = async (): Promise<Array<MenuItem>> => {
  const t = await getTranslations();
  const user = await cachedGetUserInfo();

  const menuItems = [
    {
      key: 'portfolio',
      path: generatePath(Routes.Portfolio),
      label: t('header.menu.portfolio'),
      Icon: <Icons.Document />,
    },
    {
      key: 'favorite',
      path: generatePath(Routes.FavoriteProjects),
      label: t('header.menu.favorite'),
      Icon: <Icons.Star />,
    },
    {
      key: 'transactions',
      path: generatePath(Routes.Transactions),
      label: t('header.menu.transactions'),
      Icon: <ExitIcon />,
    },
    {
      key: 'wallet',
      path: generatePath(Routes.Wallet),
      label: t('header.menu.wallet'),
      Icon: <Icons.CreditCard />,
    },
    {
      key: 'rib',
      path: generatePath(Routes.Rib),
      label: t('header.menu.rib'),
      Icon: <Icons.Edit />,
    },
    {
      key: 'company',
      path: generatePath(Routes.Company),
      label: t('header.menu.company'),
      Icon: <Icons.Building />,
      hide: !user?.isCompany,
    },
    {
      key: 'details',
      path: generatePath(Routes.Profile),
      label: t('header.menu.details'),
      Icon: <Icons.Account />,
    },
  ];

  return menuItems.filter((menuItem) => !menuItem.hide);
};
