import {
  AnalyticsClient,
  EventPayload,
  UserInfoPayload,
} from '@/lib/analytics/types';
import { ClientLogger } from '@/lib/logger/client-logger';

export default class MockAnalyticsClient implements AnalyticsClient {
  private logger: ClientLogger;

  constructor(logger: ClientLogger) {
    this.logger = logger;
  }

  public sendInitialDataLayerPush(payload: UserInfoPayload) {
    this.logger.info({ payload });
  }

  public sendEvent(payload: EventPayload) {
    this.logger.info({ payload });
  }
}
