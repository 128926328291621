'use client';

import { IdCardIcon } from '@radix-ui/react-icons';

import { useTranslations } from 'next-intl';

import { redirectToSuitableSignUpFunnelStep } from '@/app/[locale]/(auth)/sign-up/action';
import { DropdownMenuItem } from '@/app/_components/ui/dropdown-menu';

const CompleteFolderCta = () => {
  const t = useTranslations();

  const handleCompleteFolder = async () => {
    await redirectToSuitableSignUpFunnelStep();
  };

  return (
    <DropdownMenuItem
      className="flex cursor-pointer gap-x-4 px-2 text-base"
      onClick={() => handleCompleteFolder()}
    >
      <div className="flex size-4 items-center">
        <IdCardIcon />
      </div>
      {t('header.menu.complete-folder')}
    </DropdownMenuItem>
  );
};

export default CompleteFolderCta;
