import clientConfig from '@/lib/config/client-config';
import clientLogger from '@/lib/logger/client-logger';

import GoogleTagManagerClient from './clients/google-tag-manager';
import MockAnalyticsClient from './clients/mock';
import { AnalyticsManager } from './manager';

const analyticsManager = new AnalyticsManager(
  clientConfig.analytics.enabled
    ? new GoogleTagManagerClient(clientLogger)
    : new MockAnalyticsClient(clientLogger),
);

export default analyticsManager;
