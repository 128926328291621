'use client';

import { useTranslations } from 'next-intl';

import { logout } from '@/app/_components/header/action';
import { DropdownMenuItem } from '@/app/_components/ui/dropdown-menu';

const LogoutComponent = () => {
  const t = useTranslations();
  const handleLogout = async () => {
    await logout();
  };

  return (
    <DropdownMenuItem
      className="cursor-pointer px-2 text-base"
      onClick={() => handleLogout()}
    >
      {t('auth.logout')}
    </DropdownMenuItem>
  );
};

export default LogoutComponent;
