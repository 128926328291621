'use client';

import { useSelectedLayoutSegments } from 'next/navigation';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

import Link from '@/lib/translation/components/link';
import { Locale } from '@/lib/translation/config';
import { cn } from '@/lib/utils';

type NavLinkProps = ComponentPropsWithoutRef<typeof Link>;

const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  (props: NavLinkProps, ref) => {
    const { href, locale } = props;
    const selectedLayoutSegments = useSelectedLayoutSegments();
    const pathname = `/${selectedLayoutSegments.join('/')}`;
    const isActive = pathname === href;

    return (
      <Link
        {...props}
        ref={ref}
        linkCategory="menu"
        className={cn(
          isActive ? 'text-primary' : '',
          'hover:text-primary group-hover:text-primary',
          props.className,
        )}
        locale={locale as Locale}
      />
    );
  },
);

export default NavLink;
