/*
 * `generatePath`
 * heavily inspired by react-router method
 * Doc: https://reactrouter.com/utils/generate-path
 * Implementation: https://github.com/remix-run/react-router/blob/main/packages/router/utils.ts#L789
 */

import Routes from './routes';

const segmentSeparator = '/' as const;
type SegmentSeparator = typeof segmentSeparator;

const wildcard = '*' as const;
type Wildcard = typeof wildcard;

const dynamicRouteIdentifier = ':' as const;
type DynamicRouteIdentifier = typeof dynamicRouteIdentifier;
const dynamicRouteRegex = new RegExp(`^${dynamicRouteIdentifier}(\\w+)$`);

type PathParamRec<Path extends string> =
  Path extends `${infer L}${SegmentSeparator}${infer R}`
    ? PathParamRec<L> | PathParamRec<R> // recursive call
    : Path extends `${DynamicRouteIdentifier}${infer Param}`
      ? Param // add the route parameter to the type
      : never;

export type PathParam<
  Routes extends string,
  Path extends Routes,
> = Path extends Wildcard | `${SegmentSeparator}${Wildcard}`
  ? Wildcard // case if `Path` is just '*' or '/*'
  : Path extends `${infer Rest}${SegmentSeparator}${Wildcard}`
    ? Wildcard | PathParamRec<Rest> // case if `Path` ends with '/*
    : PathParamRec<Path>;

export const createGeneratePath = <Routes extends string>() => {
  return <Path extends Routes>(
    path: Path,
    params: {
      [key in PathParam<Routes, Path>]: string | null;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } = {} as any,
  ): string => {
    return (
      // add back the starting '/' if the path was absolute
      (path.startsWith(segmentSeparator) ? segmentSeparator : '') +
      path
        // get each segment of the path
        .split(segmentSeparator)
        // replace dynamic route segments by their values
        .map((segment, i, arr) => {
          // '*' is interpolated only if it's the last segment
          if (segment === wildcard && i === arr.length - 1) {
            return params[wildcard as PathParam<Routes, Path>];
          }

          // if the segment is a dynamic route it is replaced by its value
          const dynamicRouteMatch = dynamicRouteRegex.exec(segment);
          if (dynamicRouteMatch) {
            return params[dynamicRouteMatch[1] as PathParam<Routes, Path>];
          }

          // return all static segments
          return segment;
        })
        // remove any empty or nullish segment
        .filter((segment) => !!segment)
        // reconstruct the full path
        .join(segmentSeparator)
    );
  };
};

const generatePath = createGeneratePath<Routes>();

export default generatePath;
