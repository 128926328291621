/* eslint-disable import/prefer-default-export */
import { useLocale as useUntypedLocale } from 'next-intl';
import { createLocalizedPathnamesNavigation } from 'next-intl/navigation';

import { i18n, pathnames } from './config';

// https://github.com/amannn/next-intl/discussions/477
export function useLocale() {
  return useUntypedLocale() as (typeof i18n.locales)[number];
}

const { Link, redirect, usePathname, useRouter } =
  createLocalizedPathnamesNavigation({
    locales: i18n.locales,
    localePrefix: 'as-needed',
    // eslint-disable-next-line @typescript-eslint/ban-types
    pathnames: pathnames as typeof pathnames & Record<string & {}, string>,
  });

export { Link, redirect, usePathname, useRouter };
