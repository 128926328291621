'use client';

import { PropsWithChildren, createContext, useContext, useMemo } from 'react';

import { User } from '@/domain/user/services/types';

interface AuthContextValue {
  user?: User;
}

const AuthContext = createContext<AuthContextValue>({});

interface AuthContextProviderProps extends PropsWithChildren {
  user?: User;
}

const AuthContextProvider = ({ children, user }: AuthContextProviderProps) => {
  const memoizedInitialValue = useMemo(() => ({ user }), [user]);

  return (
    <AuthContext.Provider value={memoizedInitialValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthContextProvider;
