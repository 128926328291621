import clientConfig from '@/lib/config/client-config';
import { ClientLoggerType, LogLevel } from '@/lib/config/types';
import errorReporting from '@/lib/error-reporting';

import { IClientLogger } from './types';

const LevelsPrority: { [Index in LogLevel]: number } = {
  error: 1,
  warn: 2,
  info: 3,
  debug: 4,
};

const prodLogger: IClientLogger = {
  // eslint-disable-next-line no-empty-function
  debug: () => {}, // DO NOTHING
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  info: (message: any, ...data: any[]) => {
    errorReporting.info(message, data);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  warn: (message: any, ...data: any[]) => {
    errorReporting.warn(message, data);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: (message: any, ...data: any[]) => {
    errorReporting.error(message, data);
  },
};

const clientLoggers: { [log in ClientLoggerType]: IClientLogger } = {
  [ClientLoggerType.console]: console,
  [ClientLoggerType.prod]: prodLogger,
};

export class ClientLogger implements IClientLogger {
  private logger: IClientLogger;

  private level: LogLevel;

  constructor(logger: IClientLogger, level: LogLevel) {
    this.logger = logger;
    this.level = level;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private log(level: LogLevel, message: any, ...data: any[]) {
    if (LevelsPrority[level] > LevelsPrority[this.level]) {
      return;
    }
    this.logger[level](message, data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  debug(message: any, ...data: any[]) {
    this.log(LogLevel.debug, message, data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  info(message: any, ...data: any[]) {
    this.log(LogLevel.info, message, data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  warn(message: any, ...data: any[]) {
    this.log(LogLevel.warn, message, data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error(message: any, ...data: any[]) {
    this.log(LogLevel.error, message, data);
  }
}

export default new ClientLogger(
  clientLoggers[clientConfig.logger.type],
  clientConfig.logger.level,
);
