import { sendGTMEvent } from '@next/third-parties/google';

import {
  AnalyticsClient,
  EventPayload,
  propertyToClear,
  UserInfoPayload,
} from '@/lib/analytics/types';
import { ClientLogger } from '@/lib/logger/client-logger';

export default class GoogleTagManagerClient implements AnalyticsClient {
  private logger: ClientLogger;

  constructor(logger: ClientLogger) {
    this.logger = logger;
  }

  public sendInitialDataLayerPush(payload: UserInfoPayload) {
    this.logger.debug('Initial data layer push', payload);
    window.dataLayer = window.dataLayer ?? [];
    window.dataLayer.push(payload);
  }

  private resetPayloadExceptUserInfo() {
    this.logger.debug('Reset payload');
    const currentDataLayer = window.dataLayer;
    if (!currentDataLayer) {
      return {};
    }

    const dataLayerClearer = currentDataLayer.reduce((acc, curr) => {
      const keys = Object.keys(curr).filter((key) =>
        (propertyToClear as Array<string>).includes(key),
      );
      return {
        ...acc,
        ...keys.reduce((acc2, curr2) => ({ ...acc2, [curr2]: undefined }), {}),
      };
    }, {});

    return dataLayerClearer;
  }

  public sendEvent(payload: EventPayload) {
    const reset = this.resetPayloadExceptUserInfo();
    this.logger.debug(`Send Event - ${payload.event}`, {
      ...reset,
      ...payload,
    });

    sendGTMEvent({ ...reset, ...payload });
  }
}
