'use client';

import { Cross1Icon, HamburgerMenuIcon } from '@radix-ui/react-icons';

import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

import { redirectToSuitableSignUpFunnelStep } from '@/app/[locale]/(auth)/sign-up/action';
import { useAuthContext } from '@/app/[locale]/_providers/auth';
import { logout } from '@/app/_components/header/action';
import Logo from '@/app/_components/header/components/logo';
import { isChildrenMenuItem } from '@/app/_components/header/config';
import { MenuItem } from '@/app/_components/header/type';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/app/_components/ui/accordion';
import { Button } from '@/app/_components/ui/button';
import Routes from '@/lib/router/routes';
import Link from '@/lib/translation/components/link';
import { usePathname } from '@/lib/translation/hooks';
import { cn } from '@/lib/utils';

interface MobileMenuProps {
  menu: Array<MenuItem>;
  connectedMenu: Array<MenuItem>;
  hasUserInvestmentToSign: boolean;
  showCompleteFolderCta: boolean;
}

const MobileMenu = ({
  menu,
  connectedMenu,
  hasUserInvestmentToSign,
  showCompleteFolderCta,
}: MobileMenuProps) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const t = useTranslations();

  const pathname = usePathname();

  const { user } = useAuthContext();

  const searchParams =
    pathname !== Routes.Home
      ? `?${new URLSearchParams({ origin: pathname }).toString()}`
      : '';

  const handleLogout = async () => {
    await logout();
  };

  // If the pathname change it means we navigated using one of the link.
  // We need to close the menu
  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [pathname]);

  // If the pathname didn't change, but we click on the item matching our current location
  // The user will also expect to close the menu
  const closeMobileMenu = (target: Routes | string) => {
    if (pathname === target) {
      setIsMobileMenuOpen(false);
    }
  };

  const handleCompleteFolder = async () => {
    await redirectToSuitableSignUpFunnelStep();
  };

  // Why we do not only uses the `closeMobileMenu` function ?
  // Because we close the menu BEFORE navigating resulting in a glitchy behavior
  // Closing the menu / Displaying current Page / Moving to other page / Displaying other page

  return (
    <div
      // eslint-disable-next-line react/no-unknown-property
      page-locked={isMobileMenuOpen ? 'true' : undefined}
      className="flex items-center lg:hidden"
    >
      <Button
        variant="ghost"
        type="button"
        size="icon"
        aria-label={t('header.menu.toggle-label')}
        onClick={() => setIsMobileMenuOpen(true)}
      >
        <HamburgerMenuIcon className="size-9" />
      </Button>
      {isMobileMenuOpen ? (
        <div className="fixed left-0 top-0 z-50 flex h-screen w-full flex-col bg-white">
          <div className="flex items-center justify-end border-b p-6">
            <Logo
              size="small"
              className="absolute left-1/2 -translate-x-1/2"
              onClick={() => closeMobileMenu(Routes.Home)}
            />
            <Button
              variant="ghost"
              size="icon"
              type="button"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              <Cross1Icon className="size-9" />
            </Button>
          </div>
          <div className="flex min-h-0 flex-col items-center justify-between">
            <div className="flex w-full grow flex-col items-center justify-start gap-16 overflow-auto p-6">
              <Accordion type="single" className="flex w-full flex-col gap-4">
                {menu.map((item) =>
                  isChildrenMenuItem(item) ? (
                    <AccordionItem
                      className="bg-white"
                      value={item.path}
                      key={item.path}
                    >
                      <AccordionTrigger>
                        <Link
                          linkCategory="menu"
                          onClick={() => closeMobileMenu(item.path)}
                          className="w-full"
                          href={item.path}
                        >
                          {item.label}
                        </Link>
                      </AccordionTrigger>
                    </AccordionItem>
                  ) : (
                    <AccordionItem
                      className="bg-white"
                      key={item.key}
                      value={item.key}
                    >
                      <AccordionTrigger className="bg-white">
                        {item.label}
                      </AccordionTrigger>
                      <AccordionContent className="mt-2 flex flex-col gap-4 bg-white">
                        {item.items.map((childrenItem) => (
                          <Link
                            linkCategory="menu"
                            onClick={() => closeMobileMenu(childrenItem.path)}
                            className="w-full"
                            key={childrenItem.path}
                            href={childrenItem.path}
                          >
                            &gt; {childrenItem.label}
                          </Link>
                        ))}
                      </AccordionContent>
                    </AccordionItem>
                  ),
                )}
                {user ? (
                  <AccordionItem value="user">
                    <AccordionTrigger>
                      {t('header.menu.my-account')}
                    </AccordionTrigger>
                    <AccordionContent className="flex flex-col gap-4 bg-white">
                      {connectedMenu.map((item) =>
                        isChildrenMenuItem(item) ? (
                          <Link
                            linkCategory="menu"
                            onClick={() => closeMobileMenu(item.path)}
                            className="w-full"
                            key={item.path}
                            href={item.path}
                          >
                            <p
                              className={cn(
                                (item.key === 'details' && !user.isVerified) ||
                                  (hasUserInvestmentToSign &&
                                    item.key === 'portfolio')
                                  ? 'relative after:absolute after:-top-1 after:size-3 after:rounded-full after:bg-complementary-muted after:text-center'
                                  : '',
                              )}
                            >
                              &gt; {item.label}
                            </p>
                          </Link>
                        ) : null,
                      )}
                    </AccordionContent>
                  </AccordionItem>
                ) : null}
                {showCompleteFolderCta ? (
                  <Button
                    onClick={() => handleCompleteFolder()}
                    className="w-full"
                  >
                    {t('header.menu.complete-folder')}
                  </Button>
                ) : null}
              </Accordion>
            </div>
            <div className="w-full shrink-0 p-6">
              {user ? (
                <Button
                  onClick={() => handleLogout()}
                  className="w-full"
                  size="lg"
                  variant="outline"
                >
                  {t('auth.logout')}
                </Button>
              ) : (
                <div className="flex w-full flex-col gap-4">
                  <Link
                    linkCategory="menu"
                    onClick={() => closeMobileMenu(Routes.SignIn)}
                    className="w-full"
                    href={`${Routes.SignIn}${searchParams}`}
                  >
                    <Button className="w-full" size="lg" variant="outline">
                      {t('auth.signIn.title')}
                    </Button>
                  </Link>
                  <Link
                    linkCategory="menu"
                    onClick={() => closeMobileMenu(Routes.SignUpRegister)}
                    className="w-full"
                    href={`${Routes.SignUpRegister}${searchParams}`}
                  >
                    <Button className="w-full" size="lg">
                      {t('auth.signUp.cta')}
                    </Button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MobileMenu;
