'use client';

import { useTranslations } from 'next-intl';

import { useAuthContext } from '@/app/[locale]/_providers/auth';
import { isChildrenMenuItem } from '@/app/_components/header/config';
import { MenuItem } from '@/app/_components/header/type';
import {
  DropdownMenuItem,
  DropdownMenuSeparator,
} from '@/app/_components/ui/dropdown-menu';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/app/_components/ui/tooltip';
import Routes from '@/lib/router/routes';
import Link from '@/lib/translation/components/link';

import { usePathname } from '@/lib/translation/hooks';
import { cn } from '@/lib/utils';

interface UserMenuProps {
  connectedMenu: Array<MenuItem>;
}

const UserMenu = ({ connectedMenu }: UserMenuProps) => {
  const pathname = usePathname();
  const { user } = useAuthContext();
  const t = useTranslations();
  const dropDownMenu = connectedMenu.filter((menuItem) => {
    return isChildrenMenuItem(menuItem) && menuItem.path !== Routes.Portfolio;
  });

  if (!user) {
    return null;
  }

  return (
    <>
      {dropDownMenu.filter(isChildrenMenuItem).map((menuItem) => (
        <div key={menuItem.key}>
          <Link href={menuItem.path}>
            <DropdownMenuItem
              className={cn(
                'flex cursor-pointer items-center gap-x-4 px-2 text-base',
                menuItem.path === pathname && 'text-primary',
              )}
            >
              {menuItem.Icon ? (
                <div className="flex size-4 items-center">{menuItem.Icon}</div>
              ) : null}
              {menuItem.key === 'details' && !user.isVerified ? (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <p className="relative after:absolute after:-top-1 after:size-3 after:rounded-full after:bg-complementary-muted after:text-center">
                        {menuItem.label}
                      </p>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>{t('header.menu.detailsTooltip')}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ) : (
                <p>{menuItem.label}</p>
              )}
            </DropdownMenuItem>
          </Link>

          {['favorite', 'wallet'].includes(menuItem.key) ? (
            <DropdownMenuSeparator className="mx-4" />
          ) : null}
        </div>
      ))}
    </>
  );
};

export default UserMenu;
